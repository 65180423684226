<template>
    <div class="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div class="row text-center">
        <div class="col-12 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="bi bi-exclamation-triangle-fill text-warning mb-4" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
        </div>
        <div class="col-12 mb-4">
          <h1 class="display-3 fw-bold text-primary">404</h1>
          <h2 class="h3 mb-3 text-secondary">Page Not Found</h2>
          <p class="lead text-muted mb-4">
            Oops! The page you're looking for doesn't exist or has been moved.
          </p>
        </div>
        <div class="col-12">
          <router-link 
            to="/" 
            class="btn bg-gradient-primary btn-lg px-4 py-2 shadow-sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-door me-2" viewBox="0 0 16 16">
              <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5h1.75v6h2.5v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h2.5v-6h1.75a.5.5 0 0 0 .354-.854l-6-6z"/>
            </svg>
            Return to Home
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    metaInfo: {
      title: '404 - Page Not Found'
    },
    beforeMount() {
      this.$store.state.hideConfigButton = true;
      this.$store.state.showNavbar = false;
      this.$store.state.showSidenav = false;
      this.$store.state.showFooter = false;
      this.$store.state.hideMenuTab = true;

      document.body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
      this.$store.state.hideConfigButton = false;
      this.$store.state.showNavbar = true;
      this.$store.state.showSidenav = true;
      this.$store.state.showFooter = true;
      document.body.classList.add("bg-gray-100");
    }
  }
  </script>
  
  <style scoped>
  .bg-light {
    background-color: #f8f9fa !important;
  }
  
  .text-primary {
    color: #0d6efd !important;
  }
  
  .text-secondary {
    color: #6c757d !important;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  
  .btn-primary {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    transition: all 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0b5ed7 !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
  }
  </style>