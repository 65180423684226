<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";

const store = useStore();
const user = sessionStorage.getItem("user");
const route = useRoute();
const currentRoute = computed(() => store.state.currentRoute);
const toggleSideNav = () => store.commit("toggleSideNav");


</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg container-fluid shadow-none"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="container-fluid  bg-white shadow-lg border-radius-xl p-2 px-3">
      <div class="d-flex gap-2 align-items-center">
        <div>

          <i class="fa"
          :class="`${currentRoute.icon}`" 
          ></i>
        </div>
        <div>
          <span class="mb-0 fwt-bold" >{{ currentRoute.title }}</span>
        </div>
      </div>
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 "
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center ms-md-auto"
        >
      
        </div>
        <ul class="navbar-nav justify-content-end align-items-center gap-4 ">
          <li>
            <router-link :to="{ name: 'New Contract' }"   v-if="currentRoute.title!=='New Contract'" class="btn btn-primary btn-sm mb-0 p-2 fwt-bold " >
              <i class="fa fa-plus me-2"></i>
              New Contract
            </router-link>
          </li>
          <li  v-if="currentRoute.title!=='New Contract'" style="border-right: 1px solid lightgray; height:30px;"></li>
          <li class="nav-item d-flex align-items-center">
            <div class="bg-gradient-primary p-2 rounded-circle d-flex align-items-center justify-content-center">
              <i class="fa fa-user text-white"></i>
            </div>
            <div class="ms-2">
              <p class="text-sm mb-0 text-capitalize font-weight-bold">
                {{ user }}
              </p>
            </div>
          </li>
          
          <li class="nav-item  d-flex align-items-center">
            <a
              href="#"
              @click="toggleSideNav"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-black"></i>
                <i class="sidenav-toggler-line bg-black"></i>
                <i class="sidenav-toggler-line bg-black"></i>
              </div>
            </a>
          </li>
        
        
        </ul>
      </div>
    </div>
  </nav>
</template>
