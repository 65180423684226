<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import LoadingSpinner from "../components/LoadingSpinner.vue";

const store = useStore();
const router = useRouter();
const title = ref("");
const startingDate = ref(new Date().toISOString().substr(0, 10));
const buildersMargain = ref(0);
const contractFiles = ref([]);
const contractScopeFile = ref(null);
const error = ref("");
const isDragActive = ref(false);
const toast = useToast();
const loading = ref(false)

const apiEndpoint = store.state.apiEndpoint;

// Drag and drop file handling
const handleDragEnter = (e) => {
  e.preventDefault();
  e.stopPropagation();
  isDragActive.value = true;
};

const handleDragLeave = (e) => {
  e.preventDefault();
  e.stopPropagation();
  isDragActive.value = false;
};

const handleDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
  isDragActive.value = true;
};

const handleDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  isDragActive.value = false;

  const droppedFiles = e.dataTransfer.files;
  addFiles(droppedFiles);
};

const handleFileInput = (e) => {
  const inputFiles = e.target.files;
  addFiles(inputFiles);
};

const addFiles = (files) => {
  for (let file of files) {
    // Check file type
    const allowedTypes = ['.pdf', '.doc', '.docx'];
    const fileExt = '.' + file.name.split('.').pop().toLowerCase();

    if (allowedTypes.includes(fileExt)) {
      // Check if file is not already added
      if (!contractFiles.value.some(f => f.name === file.name)) {
        contractFiles.value.push(file);
      }
    } else {
      toast.error(`Invalid file type: ${file.name}. Only PDF, DOC, DOCX allowed.`);
    }
  }
};

const removeFile = (fileToRemove) => {
  contractFiles.value = contractFiles.value.filter(file => file !== fileToRemove);

  // If the removed file was the contract scope, reset it
  if (contractScopeFile.value === fileToRemove) {
    contractScopeFile.value = null;
  }
};

const handleSubmit = async () => {
  // Validation checks
  if (!title.value || !startingDate.value) {
    error.value = "Please fill in all fields";
    return;
  }

  if (contractFiles.value.length === 0) {
    error.value = "Please upload at least one contract file";
    return;
  }

  if (!contractScopeFile.value) {
    error.value = "Please select a contract scope file";
    return;
  }

  error.value = "";

  loading.value = true

  const token = sessionStorage.getItem("token");
  const formData = new FormData();
  formData.append("title", title.value);
  formData.append("startingDate", startingDate.value);
  formData.append("buildersMargin", buildersMargain.value);

  // Append all files
  contractFiles.value.forEach((file, index) => {
    formData.append(`contractFiles`, file);
  });

  // Append contract scope file index
  const scopeFileIndex = contractFiles.value.indexOf(contractScopeFile.value);
  formData.append("contractScopeIndex", scopeFileIndex);

  try {
    const response = await axios.post(`${apiEndpoint}/new_contract/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const contractId = response.data.slug;
    const propertyCount = response.data.property_count;
    router.push(`/contract/${contractId}`);
    var message = 'Contract created successfully.';

    if (propertyCount > 0) {
      message += ` ${propertyCount} properties extracted.`;
    }
    
    toast.success(message);
    loading.value = false

  } catch (err) {
    error.value = "An error occurred while creating the contract";
    toast.error("An error occurred while creating the contract");
    console.error(err);
  }
};

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
});
onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = true;
  store.state.showFooter = false;
  store.state.hideConfigButton = true;
  store.state.currentRoute={
    title: "New Contract",
    icon: "fa-plus",
    color:"success"
  }
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = false;
  store.state.hideConfigButton = false;
});
</script>

<template>
  <LoadingSpinner v-if="loading" message="Creating your contract and extracting data from scope..."
    :fullScreen="true" />
  <main id="new-contract">
    <div class="container-fluid">
      <div class="page-header min-height-300" style="
          margin-right: -24px;
          margin-left: -34%;
        "
        :style="{
        backgroundImage: 'url(' + require('@/assets/img/skyscraper.jpg') + ')',
      }"
        >
        <span class="mask bg-gradient-info opacity-6"></span>
      </div>
    </div>
    <div class="py-4 container-fluid mt-n6">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Contract</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Title</label>
                  <argon-input type="text" v-model="title" required />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Starting Date</label>
                  <argon-input type="date" v-model="startingDate" required />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <label class="example-text-input">Contract Files</label>
                  <div class="drag-drop-area border border-dashed rounded p-4 text-center"
                    :class="{ 'drag-active': isDragActive }" @dragenter.prevent="handleDragEnter"
                    @dragleave.prevent="handleDragLeave" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop"
                    @click="$refs.fileInput.click()">
                    <input type="file" ref="fileInput" multiple accept=".pdf,.doc,.docx" class="d-none"
                      @change="handleFileInput" />
                    <div class="d-flex flex-column align-items-center">
                      <i class="fa fa-cloud-upload fa-3x text-muted mb-3"></i>
                      <p class="text-muted">
                        Drag and drop files here or click to upload
                      </p>
                      <small class="text-muted">(PDF, DOC, DOCX only)</small>
                    </div>
                  </div>
                </div>

                <!-- File List with Radio Button Selection -->
                <div v-if="contractFiles.length > 0" class="col-md-6 mb-4">
                  <label class="example-text-input">Select Scope Document</label>
                  <div class="list-group">
                    <label v-for="(file, index) in contractFiles" :key="index"
                      class="list-group-item list-group-item-action d-flex justify-content-between align-items-center m-0">
                      <div class="d-flex align-items-center">
                        <input type="radio" class="form-check-input me-3" name="contractScope" :value="file"
                          v-model="contractScopeFile" />
                        <span>{{ file.name }}</span>
                      </div>
                      <button class="btn btn-link text-danger m-0" @click.prevent="removeFile(file)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Builders Margin (%)</label>
                <argon-input type="number" v-model="buildersMargain" required />
              </div>

              <hr class="horizontal dark" />
              <div v-if="error" class="alert alert-danger w-50 text-light text-center mx-auto" role="alert">
                {{ error }}
              </div>

              <div class="col-md-12 text-center">
                <argon-button class="w-75" color="info" @click="handleSubmit">Start Contract</argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.drag-drop-area {
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.drag-drop-area:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.drag-active {
  background-color: rgba(0, 123, 255, 0.1);
  border-color: #007bff !important;
}
</style>