<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 overflow-hidden"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/"
          :class="getRoute() === '' ? 'active' : ''"
          :navText="'Contracts'"
        >
          <template v-slot:icon>
            <i class="fa fa-file-pdf text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/new-contract"
          :class="getRoute() === '/new-contract' ? 'active' : ''"
          :navText="'New Contract'"
        >
          <template v-slot:icon>
            <i class="fa fa-plus text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signout"
          :navText="'Sign Out'"
        >
          <template v-slot:icon>
            <i class="fa fa-power-off text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>

</template>
