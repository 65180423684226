<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body py-1 mt-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="text-uppercase text-sm">Contract Settings</div>
                            <div>
                                <a href="#" class="p-0 nav-link border border-primary p-2 rounded" :class="[showSubMenu ? 'show' : '']"
                                    id="contractSubmenu" data-bs-toggle="dropdown" aria-expanded="false"
                                    @click="showSubMenu = !showSubMenu">
                                    More Options
                                    <i class="fa fa-arrow-down ms-2" aria-hidden="true"
                                    :class="selected ? 'text-white' : ''"></i>
                                </a>
                                <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                                    :class="showSubMenu ? 'show' : ''" aria-labelledby="contractSubmenu">
                                    <li class="mb-2" v-if="contractObj.status !== 1">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="changeStatus(1)">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-spinner"></i>
                                                </div>
                                                <div>
                                                    Make In Progress
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2" v-if="contractObj.status !== 3">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="changeStatus(3)">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-times-circle"></i>
                                                </div>
                                                <div>
                                                    Cancel Contract
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2" v-if="contractObj.status !== 2">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="changeStatus(2)">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-flag-checkered text-success "></i>
                                                </div>
                                                <div>
                                                    Complete Contract
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2" v-if="contractObj.tender_status !== 2">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="changeStatus(2, true)">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-thumbs-up text-success "></i>
                                                </div>
                                                <div>
                                                    Mark "Won"
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2" v-if="contractObj.tender_status !== 3">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="changeStatus(3, true)">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-thumbs-down text-danger "></i>
                                                </div>
                                                <div>
                                                    Mark "Lost"
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2" v-if="contractObj.status === 1">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            data-bs-toggle="modal" data-bs-target="#rescan-modal">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-rss text-warning "></i>
                                                </div>
                                                <div>
                                                    Rescan Contract
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="mb-2">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            data-bs-toggle="modal" data-bs-target="#delete-modal">
                                            <div class="py-1 d-flex align-items-center gap-3 text-end">
                                                <div class="my-auto">
                                                    <i class="fas fa-trash text-danger "></i>
                                                </div>
                                                <div>
                                                    Delete Contract
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Title</label>
                                <argon-input :disabled="disabledEdit" type="text" v-model="updatedContract.title" required />
                            </div>
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Starting Date</label>
                                <argon-input :disabled="disabledEdit" type="date" v-model="updatedContract.start_date" required />
                            </div>
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Contract Works ID</label>
                                <argon-input :disabled="disabledEdit" v-model="updatedContract.contract_number_id" />
                            </div>
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Project ID</label>
                                <argon-input :disabled="disabledEdit" v-model="updatedContract.project_number_id" />
                            </div>
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Contract Files</label>
                                <div v-for="(file, index) in updatedContract.files" :key="file.id">
                                    <div class="d-flex gap-2 align-items-center">
                                        <i class="fa fa-file"></i>
                                        <a :href="file.file" target="_blank">{{ file.filename }}</a>
                                        <i class="ms-2 fa fa-star text-primary" data-bs-toggle="tooltip"
                                            title="Scope Document" data-bs-placement="top"
                                            v-if="file.id === updatedContract.scope.id"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="example-text-input" class="form-control-label">Builders Margin (%)</label>
                                <argon-input :disabled="disabledEdit" type="number" v-model="updatedContract.builders_margin" />
                            </div>


                            <hr class="horizontal dark" />
                            <div v-if="error" class="alert alert-danger w-50 text-light text-center mx-auto"
                                role="alert">
                                {{ error }}
                            </div>

                            <div class="col-md-12 text-center d-flex justify-content-end" >
                                <a class="btn btn-info bg-gradient-info " @click="handleSave" v-if="!disabledEdit">
                                    <i class="fas fa-save me-2"></i>
                                    Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="delete-modal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteModalLabel">Are you sure you want to delete contract {{
                        updatedContract.title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div  class="modal-body">
                    The contract will be deleted, and all tables and files will be lost.<b> THIS IS NOT REVERSIBLE</b>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal"
                        @click="handleDelete">Yes Delete</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="rescan-modal" tabindex="-1" aria-labelledby="rescanModal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="rescanModal">
                        You're about to rescan the scope of {{
                        updatedContract.title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div  class="modal-body">
                    All properties and schedules will be reset and all tables will be reset to default.<b> THIS IS NOT REVERSIBLE</b>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-warning" data-bs-dismiss="modal"
                        @click="rescanContract">Yes Rescan</button>
                </div>
            </div>
        </div>
    </div>
  <LoadingSpinner v-if="loading" :full-screen="true" message="Loading contract..." />

</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import { useToast } from "vue-toast-notification";
import setTooltip from "@/assets/js/tooltip.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: 'ContractSettings',
    components: {
        ArgonInput,
        ArgonAlert,
        LoadingSpinner
    },
    props: {
        contractObj: {
            type: Object,
            required: true
        },
        loadContract: {
            type: Function,
            required: true
        }
    },
    data() {
        const store = useStore();
        const router = useRouter();
        const apiEndpoint = store.state.apiEndpoint;
        const host = store.state.host;
        const contractUrl = `${host}${this.contractObj.file}`
        return {
            apiEndpoint,
            host,
            updatedContract: this.contractObj,
            error: '',
            contractUrl,
            showSubMenu: false,
            disabledEdit : false,
            loading:false,
        }
    },
    watch: {
        contractObj(newValue) {
            this.updatedContract = newValue;
            this.disabledEdit = newValue.status !== 1;
        }
    },
    mounted() {
        setTooltip();
    },
    methods: {
        async handleSave() {
            const apiEndpoint = this.$store.state.apiEndpoint;
            const id = this.$route.params.id;
            const token = sessionStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.updatedContract.title);
            formData.append("start_date", this.updatedContract.start_date);
            formData.append("contract_number_id", this.updatedContract.contract_number_id);
            formData.append("project_number_id", this.updatedContract.project_number_id);
            formData.append("builders_margin", this.updatedContract.builders_margin);

            try {
                const response = await axios.put(`${apiEndpoint}/contracts/edit/${id}/`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                this.$toast.success("Contract updated successfully");
                await this.loadContract();
            } catch (error) {
                this.$toast.error("Error while updating contract", error);
            }
        },
        async handleDelete() {
            const apiEndpoint = this.$store.state.apiEndpoint;
            const id = this.$route.params.id;
            const token = sessionStorage.getItem('token');
            axios.delete(`${apiEndpoint}/contracts/delete/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    this.$toast.success("Contract deleted successfully");
                    this.$router.push("/");
                })
                .catch(error => {
                    this.$toast.error("Error while deleting contract", error);
                    this.$router.push("/");

                });
        },
        async changeStatus(status, tender=false) {
            const apiEndpoint = this.$store.state.apiEndpoint;
            const id = this.$route.params.id;
            const token = sessionStorage.getItem('token');
            this.showSubMenu = false
            const url = tender ? `${apiEndpoint}/contracts/change-tender-status/${id}/` : `${apiEndpoint}/contracts/change-status/${id}/`
            await axios
                .put(
                    url,
                    { status },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    this.$toast.success("Contract updated successfully");
                    this.loadContract();
                })
                .catch((error) => {
                    this.$toast.error("Error while updating contract", error);
                });
        },
        async rescanContract() {
            const apiEndpoint = this.$store.state.apiEndpoint;
            const id = this.$route.params.id;
            const token = sessionStorage.getItem('token');
            this.showSubMenu = false
            this.loading = true
            await axios
                .get(
                    `${apiEndpoint}/contracts/rescan/${id}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    this.$toast.success("Contract rescanned successfully");
                    this.loadContract();
                })
                .catch((error) => {
                    this.$toast.error("Error while rescanning contract", error);
                }).finally(() => {
                    this.loading = false
                });
        },

    }
}
</script>

<style scoped>
h3 {
    color: blue;
}
</style>