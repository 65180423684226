import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Signin from "../views/Signin.vue";
import Signout from "../views/Signout.vue";
import NewContract from "../views/NewContract.vue";
import Contract from "../views/Contract.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Tables,
    meta: { requiresAuth: true },
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signout",
    name: "signout",
    component: Signout,
  },
  {
    path: "/new-contract",
    name: "New Contract",
    component: NewContract,
    meta: { requiresAuth: true },
  },
  {
    path: "/contract/:id",
    name: "Contract",
    component: Contract,
    meta: { requiresAuth: true },
  },
  {
    path: "/404", // This catches any route that doesn't match the defined routes
    name: "NotFound",
    component: NotFound, 
  },
  {
    path: "/:pathMatch(.*)*", // This catches any route that doesn't match the defined routes
    name: "NotFound",
    component: NotFound, 
  },


  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  // },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: Signup,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
function isAuthenticated() {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return false; // No token found, not authenticated
  }

  // Decode the token's payload (middle part)
  const payloadBase64 = token.split(".")[1];
  const payload = JSON.parse(atob(payloadBase64));

  // Check if the token has expired
  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  return payload.exp > currentTime;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next("/signin"); // Redirect to login if not authenticated
    } else {
      next(); // Proceed to route if authenticated
    }
  } else if (isAuthenticated() && to.name === "Signin") {
    next("/"); // Redirect to home if already authenticated and route is signin
  } else {
    next(); // Proceed if the route does not require authentication
  }
});

export default router;
