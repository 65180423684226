import { createStore } from "vuex";
import axios from "axios";

const END_POINT = process.env.NODE_ENV === 'development' ? "http://127.0.0.1:8000" : "https://api.klasevcontracts.au";
const API_PONT = `${END_POINT}/api`
const MEDIA_POINT = `${END_POINT}/media`

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    token:null,
    user:null,
    apiEndpoint: API_PONT,
    host:END_POINT,
    currentRoute: {
      title:"",
      color:"",
      icon:""
    },
    hideMenuTab: false,
    gst: 0.1,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleSideNav(state) {
      state.showSidenav = !state.showSidenav;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    toggleSideNavFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    setUser(state, user) {
      state.user = user; // Set the user data in the state
    },
    setToken(state, token) {
      state.token = token; // Set the token in the state
    },
    clearUser(state) {
      state.user = null; // Clear the user data
      state.token = null; // Clear the token
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post(
          `${API_PONT}/login/`,
          {
            username,
            password,
          }
        );
        sessionStorage.setItem("token", response.data.tokens.access); // Save token to local storage
        sessionStorage.setItem("user", response.data.user); // Save user to local storage
        // Commit user and token to the store
        commit("setUser", response.data.user); // Assuming you have a mutation setUser
        commit("setToken", response.data.tokens.access); // Assuming you have a mutation setToken
  
        // Optionally return the response or some value if needed
        return response.data; 
      } catch (error) {
        console.log("Error: ", error)
        throw error; // Throw error to be caught in handleSubmit
      }
    },
    logout({ commit }) {
      sessionStorage.removeItem("token"); // Remove token from local storage
      commit('clearUser'); // Clear user and token upon logout
    },
  },
  getters: {},
});
