<script setup>
import { computed, ref, watch } from "vue";
import PropertySchedule2 from "./PropertySchedule2.vue";
import PropertyScope from "./PropertyScope.vue";
import Schedule3 from "./Schedule3.vue";
import ContractMenu from "../../components/ContractMenu.vue";

// Props
const props = defineProps({
    property: {
        type: Object,
        required: true
    },
    loadContract: {
        type: Function,
    },
    selectedId: {
        type: Number,
    },
    contract: {
        type: Object
    },
});

const propertyActions = ref({
    "save":() => {
    },
    "print":() => {
    },
    "reset":() => {
    }
})

const contractObj = ref({})

watch(() => props.contract, (val) => {
    contractObj.value = val
}, { immediate: true })

const disabledEdit = computed(() => contractObj.value.status !== 1);


const setActions = (action) => {
    propertyActions.value = action
}

const currentTab = ref('scope')

// TODO implement unsaved changes indicator and logic
const unsavedChanges = ref(false)
const setUnsavedChanges = (value) => {
    unsavedChanges.value = value
}
</script>

<template>
    <div class="row card shadow-lg p-2 mb-3 sticky-top" style="top: 1rem;">
        <div class="d-flex justify-content-between align-items-center ard-body">
            <ul class="nav nav-pills" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="scope-tab" data-bs-toggle="tab" data-bs-target="#scope"
                        type="button" @click="currentTab = 'scope'" role="tab" aria-controls="scope"
                        aria-selected="false">
                        <i class="fas fa-th-list"></i>
                        <span class="ms-1">Scope</span></button>
                        <div v-if="currentTab==='scope'"  class="border border-primary mx-2 border-3" ></div>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="schedule2-tab" data-bs-toggle="tab" data-bs-target="#schedule2"
                        @click="currentTab = 'schedule2'" type="button" role="tab" aria-controls="schedule2"
                        aria-selected="true">
                        <i class="fas fa-table"></i>
                        <span class="ms-1">Tender Schedule 2</span>
                    </button>
                    <div v-if="currentTab==='schedule2'"  class="border border-primary mx-2 border-3" ></div>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="schedule3-tab" data-bs-toggle="tab" data-bs-target="#schedule3"
                        @click="currentTab = 'schedule3'" type="button" role="tab" aria-controls="schedule3"
                        aria-selected="true">
                        <i class="fas fa-dollar-sign"></i>
                        <span class="ms-1">Tender Schedule 3 (EESHP)</span>
                    </button>
                    <div v-if="currentTab==='schedule3'"  class="border border-primary mx-2 border-3" ></div>

                </li>
            </ul>
            <div class="d-flex gap-2">
                <div class="d-flex align-items-center badge badge-info">
                    <span class="text-muted me-2">Builders' Margin:</span>
                    <span class="fwt-bold" style="font-weight: 1000;" > {{ property?.builders_margin }}%</span>
                </div>
                <div class="">

                        <ContractMenu
                        :scopeFileUrl="contractObj?.scope"
                        />
                   

                    <button @click="propertyActions.reset" data-bs-toggle="tooltip" v-if="propertyActions.reset && !disabledEdit"
                        title="Clicking this will reset the tables below" data-bs-placement="top"
                        class="btn m-0 btn-link text-info" >
                        <i class="fas fa-sync me-2"></i>Reset
                    </button>

                    <button 
                        class="btn m-0 bg-gradient-danger" 
                        :class="[
                            unsavedChanges ? ' pulse-button' : ''
                        ]"
                        v-if="!disabledEdit" 
                        @click="propertyActions.save"
                    >
                        <i class="fas fa-save me-2"></i>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row card shadow-lg scheduled-2 pt-2">
        <div class="card-body p-3">
            <div class="tab-content p-3" id="myTabContent">
                <div class="tab-pane fade" id="schedule2" role="tabpanel" aria-labelledby="schedule2-tab">
                    <PropertySchedule2 :property="property" v-if="currentTab === 'schedule2'"
                        :contractStatus="contractObj?.status" :scopeFileUrl="contractObj?.scope"
                        :setActions="setActions"
                        :loadContract="loadContract" />
                </div>
                <div class="tab-pane fade show active" id="scope" role="tabpanel" aria-labelledby="scope-tab">
                    <PropertyScope v-if="currentTab === 'scope'" :propertyId="property.id"
                        :contractId="contractObj?.contract_number_id" :contractStatus="contractObj?.status"
                        :setActions="setActions"
                        :address="property.address" :loadContract="loadContract" :propertyMetadata="property.metadata"
                        :scopeFileUrl="contractObj?.scope" />
                </div>
                <div class="tab-pane fade" id="schedule3" role="tabpanel" aria-labelledby="schedule3-tab">
                    <Schedule3 :contractSchedule3="contractObj.schedule3" :loadContract="loadContract"
                    :setActions="setActions"
                    :tab="currentTab"
                    :contractStatus="contractObj.status" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pulse-button {
    animation: pulse 2s infinite;
    position: relative;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(81, 7, 255, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
    }
}

.btn.disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
</style>