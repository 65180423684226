<template>
    <div class="navbar-toggle-btn d-none d-md-block position-fixed top-6 start-0 translate-middle-y"
        style="z-index: 1100; background-color: transparent;">


        <div class="bg-gradient-dark rounded rounded-circle d-flex align-items-center justify-content-center p-0"
            style="width:35px; height:35px;
    margin-left:-20px;
    ">
            <div>
                <button @click="toggleNavbar" class="btn btn-link p-0 m-0 text-white  mt-n1 ms-3">
                    <i class="fas fa-chevron-right p-0"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidenavTab',
    methods: {
        toggleNavbar() {
            this.$store.commit('toggleSideNav')
        }
    }
}
</script>
