<script setup>
import axios from 'axios';
import moment from 'moment';
import { ref, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { defaultPageSizes, getStatusColor, getStatusColorBadge } from '../../assets/js/helpers';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import ArgonInput from '../../components/ArgonInput.vue';
import { useToast } from 'vue-toast-notification';

const store = useStore();
const apiEndpoint = store.state.apiEndpoint;
const contracts = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const itemsPerPage = ref(10);
const loading = ref(false);


onBeforeMount(() => {
    store.state.imageLayout = "profile-overview";
    store.state.showNavbar = true;
    store.state.showFooter = false;
    store.state.isAbsolute = true;

    store.state.hideConfigButton = true;
    store.state.currentRoute = {
        title: "Contracts",
        icon: "fa-list",
        color: "primary"
    }
});


// Sorting state
const sortField = ref('');
const sortOrder = ref('asc');
const contractToDelete = ref({});
const toast = useToast();       

// Available page size options
const pageSizeOptions = defaultPageSizes

const token = sessionStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
};

async function fetchContracts(page = 1, keywords = '') {
    try {
        loading.value = true;
        const urlSafeKeywords = encodeURIComponent(keywords);
        const queryParams = new URLSearchParams({
            page: page,
            items_per_page: itemsPerPage.value,
            search: urlSafeKeywords || '',
            sort_by: sortField.value || 'created_at',
            sort_order: sortOrder.value || 'desc'
        });
        const response = await axios.get(
            `${apiEndpoint}/contracts/?${queryParams.toString()}`,
            config
        );
        contracts.value = response.data.results;
        totalItems.value = response.data.count;
        totalPages.value = response.data.total_pages;
        currentPage.value = response.data.current_page;
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
}

const handleSort = (field) => {
    if (sortField.value === field) {
        // Toggle order if clicking the same field
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    } else {
        // New field, default to ascending
        sortField.value = field;
        sortOrder.value = 'asc';
    }
    fetchContracts(currentPage.value);
};

const getSortIcon = (field) => {
    if (sortField.value !== field) return 'fa-sort';
    return sortOrder.value === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
};

const changePage = async (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        await fetchContracts(page);
    }
};

const changePageSize = async (event) => {
    itemsPerPage.value = parseInt(event.target.value);
    currentPage.value = 1;
    await fetchContracts(1);
};

onMounted(() => {
    fetchContracts();
});

onBeforeMount(async () => {
    store.state.showNavbar = true;
    store.state.showFooter = false;
    store.state.hideConfigButton = true;
});

let searchTimeout;
const onSearchInput = (event) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
        const keywords = event.target.value;
        fetchContracts(1, keywords);
    }, 400); // Adjust the delay as needed
}

const handleDelete = () => {
    const token = sessionStorage.getItem('token');
    axios.delete(`${apiEndpoint}/contracts/delete/${contractToDelete.value.id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            toast.success("Contract deleted successfully");
            fetchContracts();
        })
        .catch(error => {
            toast.error("Error while deleting contract", error);
        });
}
</script>

<template>
    <div class="container-fluid">
        <div class="page-header min-height-300" style="
          margin-right: -24px;
          margin-left: -34%;
        " :style="{
            backgroundImage: 'url(' + require('@/assets/img/houses2.jpg') + ')',
        }">
            <span class="mask bg-gradient-dark opacity-6"></span>
        </div>
    </div>
    <div class="py-4 container-fluid mt-n6">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="min-height: 70vh;">
                    <div class="card-header p-3 pb-0 d-flex justify-content-between align-items-center gap-2">
                        <div class="input-group w-25">
                            <span class="input-group-text">
                                <i class="fas fa-search"></i>
                            </span>
                            <input type="text" placeholder="Search contracts..." @input=onSearchInput
                                class="p-1 m-0 form-control form-control-sm " />
                        </div>
                        <div class="d-flex align-items-center">
                            <span for="pageSize" class="me-3">Items per page:</span>
                            <select id="pageSize" class="form-select pe-4 form-select-sm" :value="itemsPerPage"
                                @change="changePageSize" style="width: auto">
                                <option v-for="option in pageSizeOptions" :key="option.value" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0" v-if="contracts.length > 0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('title')">
                                            Title
                                            <i class="fas" :class="getSortIcon('title')" />
                                        </th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 cursor-pointer"
                                            @click="handleSort('properties')">
                                            Properties
                                            <i class="fas" :class="getSortIcon('properties')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('status')">
                                            Status
                                            <i class="fas" :class="getSortIcon('status')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('tender_status')">
                                            Tender Status
                                            <i class="fas" :class="getSortIcon('tender_status')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('contract_number_id')">
                                            Contract Number ID
                                            <i class="fas" :class="getSortIcon('contract_number_id')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('user')">
                                            Created By
                                            <i class="fas" :class="getSortIcon('user')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('created_at')">
                                            Creation Date
                                            <i class="fas" :class="getSortIcon('created_at')" />
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                            @click="handleSort('start_date')">
                                            Contract Starting Date
                                            <i class="fas" :class="getSortIcon('start_date')" />
                                        </th>
                                        <th class="text-secondary opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Rest of the table body remains the same -->
                                    <tr v-for="contract in contracts" :key="contract.id">
                                        <td>
                                            <router-link :to="`/contract/${contract.idslug}`"
                                                class="text-primary font-weight-bold ms-2">
                                                <i class="fas fa-file-pdf ms-1 me-2"></i> {{ contract.title }}
                                            </router-link>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ contract.properties }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <span class="badge badge-sm"
                                                :class="[getStatusColor(contract.get_status_display)]">
                                                {{ contract.get_status_display }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <span class="badge badge-sm"
                                                :class="[getStatusColorBadge(contract.tender_status)]">
                                                {{ contract.get_tender_status_display }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-secondary text-xs font-weight-bold">{{
                                                contract.contract_number_id }}</span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-secondary text-xs font-weight-bold">{{ contract.user
                                                }}</span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-secondary text-xs font-weight-bold">{{
                                                moment(contract.created_at).format('DD/MM/YYYY') }}</span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-secondary text-xs font-weight-bold">{{
                                                moment(contract.start_date).format('DD/MM/YYYY') }}</span>
                                        </td>
                                        <td class="align-middle">
                                            <button data-bs-toggle="modal" data-bs-target="#delete-modal"
                                                @click="contractToDelete = {title: contract.title, id: contract.idslug}"
                                                class="text-danger btn btn-link m-0 p-0 font-weight-bold text-xs">
                                                <i class="fas fa-trash ms-1"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- Loading indicator and pagination remain the same -->
                            <LoadingSpinner v-if="loading" message="Loading..." />

                            <div v-if="!loading && totalPages > 1" class="d-flex justify-content-center mt-4">
                                <nav aria-label="Page navigation">
                                    <ul class="pagination pagination-primary">
                                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">
                                                <i class="fas fa-angle-left"></i>
                                            </a>
                                        </li>
                                        <li v-for="page in totalPages" :key="page" class="page-item"
                                            :class="{ active: currentPage === page }">
                                            <a class="page-link" href="#" @click.prevent="changePage(page)">
                                                {{ page }}
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">
                                                <i class="fas fa-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>



                        </div>
                        <div class="text-center mt-3 text-sm text-secondary">
                            <div v-if="contracts.length === 0" class="text-lg my-3">
                                No contracts found. <a class="text-primary" href="/new-contract">Create one now</a>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-auto mb-3 text-sm text-secondary">
                        Showing {{ contracts.length }} of {{ totalItems }} items

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="delete-modal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteModalLabel">Are you sure you want to delete contract
                        {{ contractToDelete.title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    The contract will be deleted, and all tables and files will be lost.<b> THIS IS NOT REVERSIBLE</b>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal"
                        @click="handleDelete">Yes Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

th i.fas {
    margin-left: 0.5rem;
    font-size: 0.75rem;
}
</style>