<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo.png";

const store = useStore();
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const darkMode = computed(() => store.state.darkMode);
const showSidenav = computed(() => store.state.showSidenav);

</script>
<template>
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3"
    :class="`
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      
      
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="logo"
          class="navbar-brand-img h-100 mx-auto d-block"
          alt="main_logo"
        />

        <span class="ms-2 font-weight-bold me-2"></span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />

    <div
    v-if="showSidenav"
      class="sidenav-footer pt-3 mt-auto position-absolute bottom-0 end-0 w-100"
    >
      <div class="nav-item hide-menu d-none">
        <a
          href="javascript:void(0)"
          class="btn btn-link ps-1 border-radius-lg nav-link"
          id="hideSidenav"
        >
          <i class="fas fa-eye-slash opacity-6 me-2" aria-hidden="true"></i>
          <span class="nav-link-text" >Hide</span>
        </a>
      </div>
    </div>
  </aside>
</template>
